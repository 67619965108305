<template>
<div class="works">
  <left-menu current="taskrun"></left-menu>
  <div class="main">
    <el-row>
      <el-col :span="12">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/taskrun">已上交</el-breadcrumb-item>
          <el-breadcrumb-item>作品详情</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="12" style="text-align: right"
      ><el-link type="primary" :underline="false" @click="$router.back()">
        返回</el-link
      ></el-col
      >
    </el-row>
    <el-divider class="border-bottom"></el-divider>
    <p class="title-name">作品详情</p>
    <div class="header">
      <ul class="header-video">
        <li class="video-left">
          <video v-if="videoDetails" controls :src="videoDetails.videoPath"></video>
        </li>
        <li class="video-right">
          <div class="title">{{videoDetails.name}}</div>
          <div class="content"><img :src="$store.state.usermsg.avatar">{{$store.state.usermsg.name}}</div>
          <div class="time">提交时间: {{$timestampToString(videoDetails.submitTime)}}</div>
        </li>
      </ul>
      <ul class="nav">
        <li><span class="label">主演</span><span class="content" :title="videoDetails.performer" >{{videoDetails.performer}}</span></li>
        <li> <span class="label">导演</span><span class="content" :title="videoDetails.director" >{{videoDetails.director}}</span></li>
        <li> <span class="label">策划</span><span class="content" :title="videoDetails.plan" >{{videoDetails.plan}}</span></li>
        <li> <span class="label">摄像</span><span class="content" :title="videoDetails.cameraman" >{{videoDetails.cameraman}}</span></li>
        <li> <span class="label">剪辑</span><span class="content" :title="videoDetails.editor" >{{videoDetails.editor}}</span></li>
        <li> <span class="label">服化道</span><span class="content" :title="videoDetails.fuhuadao" >{{videoDetails.fuhuadao}}</span></li>
      </ul>
      <div class="footer">
        <div class="label">备注：</div><div class="remarks">
        <label v-if="videoDetails.remarks !== undefined && videoDetails.remarks.length >160" class="btn" @click="open = !open">{{open?'展开':'收起'}} <img :src="imgUrl"></label>
         <span :class="{'active':open}">
            {{videoDetails.remarks}}
         </span>
      </div>
      </div>
    </div>
    <div class="comment">
      <div class="hidden-nav">
        <span class="yelp">点评详情</span>
        <span class="collection">收藏 {{videoDetails.favNum}}</span>
        <span class="yelpNumber">点评 {{videoDetails.commentNum}}</span>
        <div class="paging"  v-if="list.length > 0">
          <img :src="leftUrl" alt="" class="leftBut" @click="leftFn()">
          <span>{{pageNum}}/{{pages}}</span>
          <img :src="rightUrl" alt="" class="rightBut" @click="rightFn()">
        </div>
      </div>
      <div class="comment-section" v-if="list.length > 0">
        <div class="message" v-for="item in list" :key="item.id">
          <img class="l" :src="item.teacherAvatar || imgSpare">
          <ul class="r">
            <li class="name">{{item.teacherName}}</li>
            <li class="content">{{item.content}}</li>
            <li class="time">{{$timestampToString(item.createTime)}}</li>
          </ul>
        </div>
         <div style="text-align: center;margin-top:30px;">
           <el-button @click="$router.back()">返回</el-button>
         </div>
      </div>
      <div v-else class="comment-section default">
        <img src="../../assets/default.png" alt="">
        <p>暂无评论~</p>
        <el-button @click="$router.back()">返回</el-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/***
 * 上传提交视频
 * cameraman  string
 * allowEmptyValue: false
 * 摄像
 *
 * director  string
 * allowEmptyValue: false
 * 导演
 *
 * editor  string
 * allowEmptyValue: false
 * 剪辑
 *
 * fuhuadao  string
 * allowEmptyValue: false
 * 服化道
 *
 * name  string
 * allowEmptyValue: false
 * 作品名称
 *
 * performer  string
 * allowEmptyValue: false
 * 演员
 *
 * plan  string
 * allowEmptyValue: false
 * 策划
 *
 * remarks  string
 * allowEmptyValue: false
 * 备注
 *
 * taskId  integer($int64)
 * allowEmptyValue: false
 * 作业id
 *
 * videoPath
 */
 let PAGE_SIZE = 6;
import LeftMenu from "@/components/LeftMenu";
import {train_taskSongDetail, train_commentList} from "@/api/train";
import VectorLeft from '../../assets/Vectorleft.png'
import VectorLeft1 from '../../assets/Vectorleft1.png'
import VectorRight from '../../assets/Vectorright.png'
import VectorRight1 from '../../assets/Vectorright1.png'
import  spareTire from  '../../assets/Headportrait.png'
export default {
  name: "VideoDetails",
  components: {LeftMenu},
  data(){
    return {
      open:true,
      videoDetails:{},
      pageNum:1,
      pages:0,
      list:[],
      leftUrl:VectorLeft,
      rightUrl:VectorRight,
      imgSpare:spareTire
    }
  },
  computed:{
    imgUrl(){
      if(this.open){
        return require('../../assets/down.png')
      }else {
        return require('../../assets/up.png')
      }
    }
  },
  created() {
   // 作业详情
   this.taskSongDetail()
    },
 methods:{
   //作业详情
   taskSongDetail(){
     train_taskSongDetail(this.$route.params.id).then(res=>{
       // console.log(res)
       if(res.code === 0){
         this.videoDetails = res.data.trainStuVideos
         //作业点评
         this.commentList()
       }
     })
   },
 //  作业点评
   commentList(){
     let p = {
       id: this.videoDetails.id,
       pageNum: this.pageNum,
       pageSize:PAGE_SIZE,
     }
     train_commentList(p).then(res=>{
       let {pageNum, pages, list} =res.data
       this.pageNum = pageNum;
       this.pages = pages;
       list.forEach(item=>{
         if(item.teacherAvatar.indexOf('http') !== 0){
             item.teacherAvatar = ''
         }
       })
       this.list = list;
       if(pageNum <= 1){
           this.leftUrl = VectorLeft1;
       }else {
         this.leftUrl = VectorLeft;
       }
       if(pageNum >= pages){
         this.rightUrl = VectorRight1;
       }else {
         this.rightUrl = VectorRight;
       }
     })
   },
//   切换点评平按钮
   leftFn(){
     if(this.pageNum > 1){
       this.pageNum--;
       this.commentList();
     }
   },
   rightFn(){
    if(this.pageNum < this.pages){
      this.pageNum++;
      this.commentList();
    }
   }

},
  // 返回首页显示已上交表格
  beforeRouteLeave(to, from, next){
    if(to.name==='Taskrun'){
      to.params.submitState = 1;
    }
    next()
}
}
</script>

<style scoped lang="scss">
 @import '../../styles/works.scss';
 *{
   margin: 0;
   padding: 0;
   list-style: none;
 }
 .main{
   border: none;
   .border-bottom{
     margin-top: 13px;
     margin-bottom: 31px;
   }
   .title-name{
     font-size: 20px;
     font-family: PingFang SC, PingFang SC-Medium;
     font-weight: 500;
     color: #666666;
     margin-bottom: 21px;
   }
   .header{
      width: 100%;
      min-height: 300px;
      background: #f8f8f8;
      border-radius: 4px;
      padding: 24px 60px 30px 30px;
     box-sizing: border-box;
    .header-video{
      display: flex;
       .video-left{
         video{
           width: 236px;
           height: 140px;
           border-radius: 4px;
         }
       }
      .video-right{
        margin-left: 20px;
        .title{
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: LEFT;
          color: #666666;
        }
        .content{
          margin-top: 13px;
          margin-bottom: 16px;
          >img{
            width: 24px;
            height: 24px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 12px;
          }
        }
        .time{
          font-size: 14px;
          font-family: Source Han Sans SC VF, Source Han Sans SC VF-Normal;
          font-weight: Normal;
          text-align: RIGHT;
          color: #999999;
        }
      }
    }
     .nav{
       margin-top: 20px;
       font-size: 14px;
       font-family: Source Han Sans SC VF, Source Han Sans SC VF-Normal;
       font-weight: Normal;
       color: #999999;
       display: flex;
       li{
         margin-right: 40px;
         white-space: nowrap;
         .label{
           margin-right: 10px;
         }
         .content{
           vertical-align: middle;
           width: 93px;
           display: inline-block;
           white-space: nowrap;
           overflow: hidden;
           text-overflow: ellipsis;
         }
       }
     }
     .footer{
       display: flex;
       font-size: 14px;
       margin-top: 22px;
       font-family: PingFang SC, PingFang SC-Regular;
       font-weight: 400;
       color: #999999;
       .label{
         white-space:nowrap;
       }
       .remarks{
         width: 1060px;
         color: #333;
         font-size: 14px;
         position: relative;
         .active {
           display: -webkit-box;
           -webkit-box-orient: vertical;
           -webkit-line-clamp: 2;
           overflow: hidden;
         }
         .btn {
           font-size: 14px;
           font-family: PingFang SC, PingFang SC-Regular;
           font-weight: 400;
           text-align: right;
           color: #ff004d;
           line-height: 16px;
           background: #F8F8F8;
           cursor: pointer;
           position: absolute;
           right: 0px;
           bottom:2px;
           width: 70px;
           background: linear-gradient(to right, rgba(248, 248, 248, 0.6), rgba(248, 248, 248, 1) 30%);
           img{
             vertical-align: middle;
             width: 12px;
             height: 12px;

           }
         }
       }
     }
   }

   .comment{
     margin-top: 24px;
     .hidden-nav{
       font-size: 0px;
       height: 58px;
       line-height: 58px;
       background: #f8f8f8;
       border-radius: 4px;
       padding: 0px 30px 0px 24px;
       box-sizing: border-box;
       .yelp{
         font-size: 16px;
         font-family: PingFang SC, PingFang SC-Medium;
         font-weight: 500;
         text-align: LEFT;
         color: #666666;
       }
       span{
         font-size: 12px;
         font-family: PingFang SC, PingFang SC-Regular;
         font-weight: 400;
         text-align: LEFT;
         color: #666666;
       }
       .collection{
         margin-left:28px;
         margin-right: 14px;
       }
       .paging{
         float: right;
         //border: 1px solid;
         height: 100%;
         width: 200px;
         text-align: right;
         font-size: 14px;
         font-family: PingFang SC, PingFang SC-Regular;
         font-weight: 400;
         color: #666666;
         img{
           width: 12px;
           height: 12px;
           cursor:pointer;
           vertical-align: middle;
         }
         .leftBut{
           margin-right: 9px;
         }
         .rightBut{
           margin-left: 9px;
         }
       }
     }
     .comment-section{
       min-height: 472px;
       .el-button{
         width: 140px;
         height: 44px;
         background: #ffffff;
         border: 1px solid #dedede;
         border-radius: 4px;
       }
       .message{
         display: flex;
         padding: 26px 0;
         border-bottom: 1px solid  #e5e5e5;
         .l{
             border-radius: 50%;
             width: 40px;
             height: 40px;
         }
         .r{
           margin-left: 18px;
           .name{
             font-size: 14px;
             font-family: PingFang SC, PingFang SC-Medium;
             font-weight: 500;
             text-align: LEFT;
             color: #666666;
           }
           .content{
             font-size: 14px;
             font-family: PingFang SC, PingFang SC-Regular;
             font-weight: 400;
             text-align: LEFT;
             color: #666666;
             width: 1071px;
             min-width: 970px;
             margin-top: 14px;
             margin-bottom:12px;
           }
           .time{
             font-size: 12px;
             font-family: PingFang SC, PingFang SC-Regular;
             font-weight: 400;
             text-align: LEFT;
             color: #999999;
             line-height: 16px;
           }
         }
       }
     }
     .default{
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       img{
         width: 112px;
         height: 105px;
       }
       p{
         font-size: 14px;
         font-family: PingFang SC, PingFang SC-Regular;
         font-weight: 400;
         color: #222222;
         line-height: 14px;
         margin-top: 16px;
         margin-bottom: 32px;
       }
     }
   }
 }
</style>
